import React, { useState, useMemo, useEffect } from 'react';
import { Container, ListGroup, Form, Button, Tabs, Tab } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';

const PavilionExhibitList = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { language } = useLanguage();

  const [activeTab, setActiveTab] = useState('exhibitors');
  const [exhibitorSearch, setExhibitorSearch] = useState('');
  const [exhibitSearch, setExhibitSearch] = useState('');

  useEffect(() => {
    console.log('Full received state:', state);
    console.log('Exhibitors:', state?.exhibitors);
    console.log('Exhibits:', state?.exhibits);
    if (state?.exhibitors) {
      console.log('First Exhibitor Properties:', 
        state.exhibitors.length > 0 ? Object.keys(state.exhibitors[0]) : 'No exhibitors');
    }
    if (state?.exhibits) {
      console.log('First Exhibit Properties:', 
        state.exhibits.length > 0 ? Object.keys(state.exhibits[0]) : 'No exhibits');
    }
  }, [state]);

  const {
    exhibitors = [],
    exhibits = [],
    exhibitionId = '',
    pavilionName = '',
  } = state || {};

  const hasValidData = exhibitors && Array.isArray(exhibitors) && exhibits && Array.isArray(exhibits);

  console.log('Has valid data:', hasValidData);
  console.log('Number of exhibitors:', exhibitors.length);
  console.log('Number of exhibits:', exhibits.length);

  const filteredExhibitors = useMemo(() => {
    if (!hasValidData) return [];
    const searchTerm = exhibitorSearch.toLowerCase();
    return exhibitors.filter((item) => {
      const nameMatch = language === 'en'
        ? (item.EponCatEng || '').toLowerCase().includes(searchTerm)
        : (item.EponCatGr || '').toLowerCase().includes(searchTerm);
      const cityMatch = language === 'en'
        ? (item.CityEn || '').toLowerCase().includes(searchTerm)
        : (item.CityGr || '').toLowerCase().includes(searchTerm);
      return nameMatch || cityMatch;
    });
  }, [hasValidData, exhibitors, exhibitorSearch, language]);

  const filteredExhibits = useMemo(() => {
    if (!hasValidData) return [];
    const searchTerm = exhibitSearch.toLowerCase();
    return exhibits.filter((item) => {
      const descMatch = language === 'en'
        ? (item.DescrEng || '').toLowerCase().includes(searchTerm)
        : (item.DescrGr || '').toLowerCase().includes(searchTerm);
      const pavMatch = language === 'en'
        ? (item.PavDescrEng || '').toLowerCase().includes(searchTerm)
        : (item.PavDescrGr || '').toLowerCase().includes(searchTerm);
      return descMatch || pavMatch;
    });
  }, [hasValidData, exhibits, exhibitSearch, language]);

  const handleExhibitorPress = (item) => {
    navigate(`/exhibitors/${item.ActivityID}`, { state: { exhibitionId } });
  };

  const handleExhibitPress = (item) => {
    navigate(`/exhibit-associated-exhibitors/${item.ExhibitCode}`, { state: { exhibitionId } });
  };

  return (
    <div style={{ backgroundColor: '#012d50', minHeight: '100vh', padding: '16px' }}>
      <Container>
        {!hasValidData ? (
          <div className="text-center">
            <p className="mt-3" style={{ color: 'white' }}>
              {language === 'en'
                ? 'Unable to load pavilion data. Please try again.'
                : 'Αδυναμία φόρτωσης δεδομένων. Παρακαλώ δοκιμάστε ξανά.'}
            </p>
            <Button variant="light" onClick={() => navigate(-1)}>
              {language === 'en' ? 'Go Back' : 'Επιστροφή'}
            </Button>
          </div>
        ) : (
          <>
            {pavilionName && (
              <div
                style={{
                  padding: '16px',
                  backgroundColor: '#001f3f',
                  borderBottom: '1px solid rgba(255,255,255,0.1)',
                  textAlign: 'center',
                }}
              >
                <h2 style={{ fontSize: '18px', fontWeight: 'bold', color: 'white' }}>
                  {pavilionName}
                </h2>
              </div>
            )}

            <Tabs
              activeKey={activeTab}
              onSelect={(k) => {
                console.log('Switching to tab:', k);
                setActiveTab(k);
              }}
              className="mb-3"
              style={{ 
                backgroundColor: '#001f3f', 
                display: 'flex',
                width: '100%',
                justifyContent:'space-around'
              }}
            >
              <Tab
                eventKey="exhibitors"
                title={
                  <div 
                    style={{
                      width: '100%', 
                      textAlign: 'center',
                      color: activeTab === 'exhibitors' ? '#007AFF' : 'white',
                      fontWeight: activeTab === 'exhibitors' ? '600' : '500',
                    }}
                  >
                    {language === 'en' ? 'Exhibitors' : 'Εκθέτες'}
                  </div>
                }
                style={{
                  flex: 1,
                  backgroundColor: activeTab === 'exhibitors' ? '#012d50' : '#023761',
                  borderBottom: activeTab === 'exhibitors' ? '2px solid #007AFF' : 'none',
                }}
              >
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder={language === 'en' ? 'Search exhibitors...' : 'Αναζήτηση εκθετών...'}
                    value={exhibitorSearch}
                    onChange={(e) => setExhibitorSearch(e.target.value)}
                    style={{
                      backgroundColor: '#fff',
                      padding: '12px',
                      borderRadius: '8px',
                      fontSize: '16px',
                    }}
                  />
                </Form.Group>
                {filteredExhibitors.length === 0 ? (
                  <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{ height: '50vh', color: 'white' }}
                  >
                    <p className="mt-3 text-center">
                      {language === 'en' ? 'No exhibitors found' : 'Δεν βρέθηκαν εκθέτες'}
                    </p>
                  </div>
                ) : (
                  <ListGroup>
                    {filteredExhibitors.map((item) => (
                      <ListGroup.Item
                        key={item.ActivityID}
                        className="d-flex align-items-center"
                        style={{
                          backgroundColor: 'rgba(70, 130, 180, 0.9)',
                          color: 'white',
                          marginBottom: '8px',
                          borderRadius: '15px',
                          border: 'none',
                          padding: '16px',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleExhibitorPress(item)}
                      >
                        <div className="flex-grow-1">
                          <div style={{ fontSize: '16px', fontWeight: 600, marginBottom: '4px' }}>
                            {language === 'en' ? item.EponCatEng : item.EponCatGr}
                          </div>
                          <div style={{ fontSize: '14px', color: 'rgba(255,255,255,0.8)' }}>
                            {language === 'en' ? item.CityEn : item.CityGr}
                          </div>
                        </div>
                        <i className="bi bi-chevron-right" style={{ color: 'white' }}></i>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </Tab>
              <Tab
                eventKey="exhibits"
                title={
                  <div 
                    style={{
                      width: '100%', 
                      textAlign: 'center',
                      color: activeTab === 'exhibits' ? '#007AFF' : 'white',
                      fontWeight: activeTab === 'exhibits' ? '600' : '500',
                    }}
                  >
                    {language === 'en' ? 'Exhibits' : 'Εκθέματα'}
                  </div>
                }
                style={{
                  flex: 1,
                  backgroundColor: activeTab === 'exhibits' ? '#012d50' : '#023761',
                  borderBottom: activeTab === 'exhibits' ? '2px solid #007AFF' : 'none',
                }}
              >
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder={language === 'en' ? 'Search exhibits...' : 'Αναζήτηση εκθεμάτων...'}
                    value={exhibitSearch}
                    onChange={(e) => setExhibitSearch(e.target.value)}
                    style={{
                      backgroundColor: '#fff',
                      padding: '12px',
                      borderRadius: '8px',
                      fontSize: '16px',
                    }}
                  />
                </Form.Group>
                {filteredExhibits.length === 0 ? (
                  <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{ height: '50vh', color: 'white' }}
                  >
                    <p className="mt-3 text-center">
                      {language === 'en' ? 'No exhibits found' : 'Δεν βρέθηκαν εκθέματα'}
                    </p>
                    <p>Debug: {exhibitSearch === '' ? 'Search is empty' : `Search term: ${exhibitSearch}`}</p>
                    <p>Debug: Total exhibits: {exhibits.length}, Filtered: {filteredExhibits.length}</p>
                  </div>
                ) : (
                  <ListGroup>
                    {filteredExhibits.map((item) => (
                      <ListGroup.Item
                        key={item.ExhibitCodeEnc}
                        className="d-flex align-items-center"
                        style={{
                          backgroundColor: 'rgba(70, 130, 180, 0.9)',
                          color: 'white',
                          marginBottom: '8px',
                          borderRadius: '15px',
                          border: 'none',
                          padding: '16px',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleExhibitPress(item)}
                      >
                        <div className="flex-grow-1">
                          <div style={{ fontSize: '16px', fontWeight: 600, marginBottom: '4px' }}>
                            {language === 'en' ? item.DescrEng : item.DescrGr}
                          </div>
                          <div style={{ fontSize: '14px', color: 'rgba(255,255,255,0.8)' }}>
                            {language === 'en' ? item.PavDescrEng : item.PavDescrGr}
                          </div>
                        </div>
                        <i className="bi bi-chevron-right" style={{ color: 'white' }}></i>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </Tab>
            </Tabs>
          </>
        )}
      </Container>
    </div>
  );
};

export default PavilionExhibitList;