import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import QrScanner from 'qr-scanner';
import { FaUser, FaPhone, FaEnvelope, FaBuilding, FaArrowLeft } from 'react-icons/fa';
import { sendPostRequest } from '../utils/fetchQueries';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';

function QrCodeScanner() {
  // State variables
  const [cameraError, setCameraError] = useState(false);
  const [vCardData, setVCardData] = useState(null);
  const [visitorData, setVisitorData] = useState(null);
  const [scannedQrCode, setScannedQrCode] = useState(null);
  const [notes, setNotes] = useState(''); // New state for notes
  const [isModalVisible, setModalVisible] = useState(false);
  const [secondModalVisible, setSecondModalVisible] = useState(false);
  const [localId, setLocalId] = useState(null);
  const [localName, setLocalName] = useState('');
  const [localToken, setLocalToken] = useState('');
  
  // Refs for video and scanner
  const videoRef = useRef(null);
  const scannerRef = useRef(null);

  // Navigation hook
  const navigate = useNavigate();

  // Retrieve id, name, and token from localStorage on mount
  useEffect(() => {
    const storedId = localStorage.getItem('id');
    const storedName = localStorage.getItem('name');
    const storedToken = localStorage.getItem('token');
    if (storedId) setLocalId(storedId);
    if (storedName) setLocalName(storedName);
    if (storedToken) setLocalToken(storedToken.replace(/"/g, ''));
  }, []);

  // Initialize scanner once on mount
  useEffect(() => {
    if (videoRef.current) {
      const scanner = new QrScanner(
        videoRef.current,
        async (result) => {
          scanner.stop();
          setScannedQrCode(result.data); // Store the scanned QR code value
          try {
            const apiResponse = await fetchUserData(result.data, localToken);
            if (apiResponse) {
              setVisitorData(apiResponse); // Store full API response
              const { PName, Telephone, Email, Company } = apiResponse;
              const [firstName, lastName] = PName.split(' ', 2);
              const contact = {
                firstName: firstName || '',
                lastName: lastName || '',
                phoneNumber: Telephone,
                email: Email,
                company: Company,
              };
              setVCardData(contact);
              setNotes(''); // Reset notes on new scan
              setModalVisible(true);
            } else {
              alert('Failed to fetch user data.');
              scanner.start();
            }
          } catch (error) {
            alert(`An error occurred: ${error.message}`);
            scanner.start();
          }
        },
        {
          onDecodeError: (err) => {
            if (err.message !== 'No QR code found' && err.name !== 'AbortError') {
              console.error('Decode error', err);
            }
          },
          highlightScanRegion: true,
          highlightCodeOutline: true,
        }
      );

      scannerRef.current = scanner;

      const timer = setTimeout(() => {
        scanner.start().catch((err) => {
          console.error('Camera access error', err);
          setCameraError(true);
        });
      }, 500);

      return () => {
        clearTimeout(timer);
        if (scannerRef.current) {
          scannerRef.current.stop();
          scannerRef.current.destroy();
          scannerRef.current = null;
        }
      };
    }
  }, [localToken]);

  // Manage scanner when either modal visibility changes
  useEffect(() => {
    if (scannerRef.current && !isModalVisible && !secondModalVisible) {
      const timer = setTimeout(() => {
        scannerRef.current.start().catch((err) => {
          console.error('Camera access error', err);
          setCameraError(true);
        });
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isModalVisible, secondModalVisible]);

  // Fetch user data from API
  const fetchUserData = async (id, localToken) => {
    try {
      const response = await sendPostRequest(id, localToken);
      console.log('res:', response);
      return response || null;
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  };

  // Generate vCard string
  const generateVCard = (contact) => {
    const { firstName, lastName, phoneNumber, email, company } = contact;
    return `
        BEGIN:VCARD
        VERSION:3.0
        FN:${firstName} ${lastName}
        N:${lastName};${firstName};;;
        ORG:${company}
        TEL;TYPE=WORK,VOICE:${phoneNumber}
        EMAIL;TYPE=INTERNET:${email}
        END:VCARD
    `.trim();
  };

  // Handle vCard download
  const handleDownloadVCard = () => {
    if (!vCardData) return;
    const vCardString = generateVCard(vCardData);
    const blob = new Blob([vCardString], { type: 'text/vcard' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${vCardData.firstName}_${vCardData.lastName}.vcf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  // Add contact to server
  const addContact = async () => {
    if (!visitorData || !scannedQrCode) return false;

    const vid = CryptoJS.AES.encrypt(JSON.stringify(visitorData.PersonID), localToken).toString();
    const eid = CryptoJS.AES.encrypt(JSON.stringify(localId), localToken).toString();
    const yourDate = new Date();
    const ScanDate = yourDate.toISOString().split('T')[0];
    const ScanTime = yourDate.toLocaleTimeString();
    const contactObj = {
      ContactType: visitorData.ContactType,
      qrCode: scannedQrCode,
      PersonID: vid,
      EkthetesID: eid,
      notes: notes, 
      ScanDate,
      ScanTime,
    };
    console.log(contactObj);
    try {
      const res = await axios.post(
        `https://api.helexpo.gr/scan/api/addcontact`,
        contactObj,
        {
          headers: {
            'Authorization': `Bearer ${localToken}`,
          },
        }
      );
      console.log(res.data);
      if (res.data.err === 0) {
        setSecondModalVisible(true);
        return true;
      } else {
        alert('Error adding contact to server.');
        return false;
      }
    } catch (err) {
      console.error('Add contact error:', err);
      alert('Error adding contact to server.');
      return false;
    }
  };

  // Handle modal actions
  const handleAccept = async () => {
    const success = await addContact();
    if (success) {
      setModalVisible(false); // Close first modal on success
      setNotes(''); // Clear notes after successful submission
    }
  };

  const handleCancel = () => {
    setModalVisible(false);
    setNotes(''); // Clear notes when canceling
  };

  // JSX with added notes field in the modal
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        backgroundColor: '#000',
        padding: '20px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <style>{`
        .scanning-box {
          border: 2px solid;
          animation: pulse 2s infinite;
        }
        @keyframes pulse {
          0% { border-color: #00ff87; }
          50% { border-color: #60efff; }
          100% { border-color: #00ff87; }
        }
      `}</style>

      {cameraError ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            textAlign: 'center',
          }}
        >
          <p style={{ fontSize: '18px' }}>
            Camera access is required to scan QR codes.
          </p>
          <p style={{ fontSize: '16px' }}>
            Please allow camera access in your browser settings. If you’ve previously denied permission, adjust it in the browser’s site settings (e.g., chrome://settings/content/camera for Chrome) and reload the page.
          </p>
        </div>
      ) : (
        <div
          className="card shadow-lg"
          style={{
            width: '100%',
            maxWidth: '350px',
            borderRadius: '15px',
            overflow: 'hidden',
          }}
        >
          <div
            className="card-header text-center py-3"
            style={{
              backgroundColor: '#012d50',
              color: 'white',
              fontSize: '18px',
              position: 'relative',
            }}
          >
            <Button
              variant="link"
              onClick={() => navigate('/')}
              style={{
                position: 'absolute',
                left: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                color: 'white',
                padding: 0,
              }}
              aria-label="Back to home"
            >
              <FaArrowLeft size={24} />
            </Button>
            QR Code Scanner
          </div>
          <div className="card-body p-0">
            <div
              style={{
                position: 'relative',
                height: '350px',
                backgroundColor: 'white',
              }}
            >
              <video
                ref={videoRef}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  pointerEvents: 'none',
                }}
              >
                <div
                  style={{
                    flex: 1,
                    backgroundColor: 'rgba(0,0,0,0.6)',
                    height: '50px',
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '250px',
                  }}
                >
                  <div
                    style={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.6)' }}
                  />
                  <div
                    className="scanning-box"
                    style={{
                      width: '250px',
                      height: '250px',
                      position: 'relative',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: -2,
                        left: -2,
                        width: '30px',
                        height: '30px',
                        borderTop: '4px solid white',
                        borderLeft: '4px solid white',
                        borderRadius: '8px',
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: -2,
                        right: -2,
                        width: '30px',
                        height: '30px',
                        borderTop: '4px solid white',
                        borderRight: '4px solid white',
                        borderRadius: '8px',
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        bottom: -2,
                        left: -2,
                        width: '30px',
                        height: '30px',
                        borderBottom: '4px solid white',
                        borderLeft: '4px solid white',
                        borderRadius: '8px',
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        bottom: -2,
                        right: -2,
                        width: '30px',
                        height: '30px',
                        borderBottom: '4px solid white',
                        borderRight: '4px solid white',
                        borderRadius: '8px',
                      }}
                    />
                  </div>
                  <div
                    style={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.6)' }}
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                    backgroundColor: 'rgba(0,0,0,0.6)',
                  }}
                />
              </div>
            </div>
            <div
              style={{
                textAlign: 'center',
                padding: '10px',
                backgroundColor: '#012d50',
                color: '#fff',
                fontSize: '16px',
                textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
              }}
            >
              {localName ? localName : 'Align QR Code within frame'}
            </div>
          </div>
        </div>
      )}

      {/* First Modal: Contact Details with Notes */}
      <Modal show={isModalVisible} onHide={handleCancel} centered>
        <Modal.Header closeButton style={{ borderBottom: 'none' }}>
          <FaUser
            style={{ color: '#2b2d42', fontSize: '28px', marginRight: '10px' }}
          />
          <Modal.Title
            style={{ fontSize: '22px', fontWeight: 600, color: '#2b2d42' }}
          >
            Contact Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            background: 'linear-gradient(to bottom, #f8f9fa, #e9ecef)',
            padding: '25px',
          }}
        >
          {vCardData && (
            <div>
              <div
                style={{
                  marginBottom: '15px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FaUser
                  style={{
                    color: '#4a4e69',
                    fontSize: '20px',
                    marginRight: '15px',
                  }}
                />
                <div>
                  <strong style={{ fontSize: '14px', color: '#6c757d' }}>
                    Name
                  </strong>
                  <div
                    style={{
                      fontSize: '16px',
                      color: '#495057',
                      fontWeight: 500,
                    }}
                  >
                    {`${vCardData.firstName} ${vCardData.lastName}`}
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginBottom: '15px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FaPhone
                  style={{
                    color: '#4a4e69',
                    fontSize: '20px',
                    marginRight: '15px',
                  }}
                />
                <div>
                  <strong style={{ fontSize: '14px', color: '#6c757d' }}>
                    Phone
                  </strong>
                  <div
                    style={{
                      fontSize: '16px',
                      color: '#495057',
                      fontWeight: 500,
                    }}
                  >
                    {vCardData.phoneNumber}
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginBottom: '15px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FaEnvelope
                  style={{
                    color: '#4a4e69',
                    fontSize: '20px',
                    marginRight: '15px',
                  }}
                />
                <div>
                  <strong style={{ fontSize: '14px', color: '#6c757d' }}>
                    Email
                  </strong>
                  <div
                    style={{
                      fontSize: '16px',
                      color: '#495057',
                      fontWeight: 500,
                    }}
                  >
                    {vCardData.email}
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginBottom: '15px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FaBuilding
                  style={{
                    color: '#4a4e69',
                    fontSize: '20px',
                    marginRight: '15px',
                  }}
                />
                <div>
                  <strong style={{ fontSize: '14px', color: '#6c757d' }}>
                    Company
                  </strong>
                  <div
                    style={{
                      fontSize: '16px',
                      color: '#495057',
                      fontWeight: 500,
                    }}
                  >
                    {vCardData.company}
                  </div>
                </div>
              </div>
              <Form.Group controlId="notes" style={{ marginTop: '20px' }}>
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Enter any notes here"
                />
              </Form.Group>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            borderTop: 'none',
            padding: '0 25px 25px 25px',
            background: 'linear-gradient(to bottom, #012d50, #012d50)',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="secondary"
            onClick={handleCancel}
            style={{
              flex: 1,
              marginRight: '10px',
              backgroundColor: '#e76f51',
              border: 'none',
              padding: '12px',
              borderRadius: '10px',
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleAccept}
            style={{
              flex: 1,
              backgroundColor: '#2a9d8f',
              border: 'none',
              padding: '12px',
              borderRadius: '10px',
            }}
          >
            Save Contact
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Second Modal: Save to Phone Confirmation */}
      <Modal show={secondModalVisible} onHide={() => setSecondModalVisible(false)} centered>
        <Modal.Header closeButton style={{ borderBottom: 'none' }}>
          <Modal.Title
            style={{ fontSize: '22px', fontWeight: 600, color: '#2b2d42' }}
          >
            Save to Phone
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            background: 'linear-gradient(to bottom, #f8f9fa, #e9ecef)',
            padding: '25px',
          }}
        >
          Contact added successfully. Do you want to save it to your phone?
        </Modal.Body>
        <Modal.Footer
          style={{
            borderTop: 'none',
            padding: '0 25px 25px 25px',
            background: 'linear-gradient(to bottom, #012d50, #012d50)',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="secondary"
            onClick={() => setSecondModalVisible(false)}
            style={{
              flex: 1,
              marginRight: '10px',
              backgroundColor: '#e76f51',
              border: 'none',
              padding: '12px',
              borderRadius: '10px',
            }}
          >
            No
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleDownloadVCard();
              setSecondModalVisible(false);
            }}
            style={{
              flex: 1,
              backgroundColor: '#2a9d8f',
              border: 'none',
              padding: '12px',
              borderRadius: '10px',
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default QrCodeScanner;