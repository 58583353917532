import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Button, Container } from 'react-bootstrap';
import { ArrowLeft, Globe, MapPin } from 'lucide-react';
import { useLanguage } from '../context/LanguageContext';

const AppNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { language, toggleLanguage } = useLanguage();
  const isHomePage = location.pathname === '/';

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Navbar 
      bg="white" 
      variant="light" 
      className="shadow-sm py-3" 
      style={{ 
        position: 'sticky', 
        top: 0, 
        zIndex: 1020,
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        backdropFilter: 'blur(10px)',
        backgroundColor: 'rgba(255,255,255,0.85)'
      }}
    >
      <Container fluid className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          {!isHomePage && (
            <Button
              variant="outline-secondary"
              onClick={handleGoBack}
              className="me-3 rounded-circle p-2 d-flex align-items-center justify-content-center"
              style={{ 
                width: '40px', 
                height: '40px',
                background: 'transparent',
                borderColor: 'rgba(0,0,0,0.1)'
              }}
            >
              <ArrowLeft size={20} className="text-dark" />
            </Button>
          )}
          <div className="d-flex align-items-center">
            <MapPin size={24} className="me-2 text-primary" />
            <Navbar.Brand 
              className="mb-0 h1 text-dark" 
              style={{ 
                fontWeight: 600,
                fontSize: '1.3rem',
                letterSpacing: '-0.5px'
              }}
            >
              Helexpo App
            </Navbar.Brand>
          </div>
        </div>

        <Button
          variant="outline-secondary"
          onClick={toggleLanguage}
          className="d-flex align-items-center justify-content-center rounded-pill px-3 py-2"
          style={{
            background: 'rgba(0,0,0,0.05)',
            borderColor: 'transparent'
          }}
        >
          <Globe size={18} className="me-2 text-dark" />
          <span style={{ 
            fontSize: '1.1rem',
            fontWeight: 500
          }}>
            {language === 'gr' ? '🇬🇷 GR' : '🇬🇧 EN'}
          </span>
        </Button>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;