import React, { useEffect, useState } from 'react';
import { Container, ListGroup, Spinner, Button } from 'react-bootstrap';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import axios from 'axios';
import { motion } from 'framer-motion'; // For animations

const ExhibitAssociatedExhibitors = () => {
  const { id } = useParams(); // Get the exhibit ID from the URL
  const { state } = useLocation(); // Get the exhibitionId from the state
  const navigate = useNavigate();
  const { language } = useLanguage();

  const [exhibits, setExhibits] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const Pavex = process.env.REACT_APP_PAVEX;
  const ExhibitForExhibitor = process.env.REACT_APP_EXHIBITORFOREXHIBIT;
  const token = process.env.REACT_APP_USETOKEN;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError('');

      const exhibitsUrl = `${Pavex}/${state?.exhibitionId}/${id}`;
      const companiesUrl = `${ExhibitForExhibitor}${state?.exhibitionId}/${id}`;

      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };

      try {
        const [exhibitsResponse, companiesResponse] = await Promise.all([
          axios.get(exhibitsUrl, { headers }),
          axios.get(companiesUrl, { headers }),
        ]);

        const exhibitsData = Array.isArray(exhibitsResponse.data.results)
          ? exhibitsResponse.data.results
          : [];
        const companiesData = Array.isArray(companiesResponse.data.results)
          ? companiesResponse.data.results
          : [];

        setExhibits(exhibitsData);
        setCompanies(companiesData);
      } catch (err) {
        setError(
          language === 'en'
            ? 'Error fetching data. Please try again later.'
            : 'Σφάλμα κατά την ανάκτηση δεδομένων. Παρακαλώ δοκιμάστε ξανά αργότερα.'
        );
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    if (state?.exhibitionId && id) {
      fetchData();
    } else {
      setError(
        language === 'en'
          ? 'Missing exhibit or exhibition ID.'
          : 'Λείπει το ID του εκθέματος ή της έκθεσης.'
      );
      setLoading(false);
    }
  }, [id, state?.exhibitionId, language, token]);

  // Loading state
  if (loading) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{
          height: '100vh',
          backgroundColor: '#012d50',
          color: 'white',
        }}
      >
        <div className="text-center">
          <Spinner animation="border" variant="light" />
          <p className="mt-3">
            {language === 'en' ? 'Loading...' : 'Φόρτωση...'}
          </p>
        </div>
      </Container>
    );
  }

  // Error state
  if (error) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          height: '100vh',
          backgroundColor: '#012d50',
          color: 'white',
        }}
      >
        <div className="text-center">
          <i className="bi bi-exclamation-circle" style={{ fontSize: '48px', color: '#FF6B6B' }}></i>
          <p className="mt-3 text-danger">{error}</p>
          <Button variant="light" onClick={() => window.location.reload()}>
            {language === 'en' ? 'Retry' : 'Επανάληψη'}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: '#012d50',
        minHeight: '100vh',
        padding: '16px',
      }}
    >
      <Container>
        <motion.div
          initial={{ opacity: 0, translateY: 50 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{ duration: 0.6 }}
        >
          {/* Exhibit Title */}
          <h2
            style={{
              fontSize: '22px',
              fontWeight: 600,
              color: 'white',
              marginBottom: '32px',
              textAlign: 'center',
            }}
          >
            {language === 'en' ? exhibits[0]?.DescrEng : exhibits[0]?.DescrGr}
          </h2>

          {/* Associated Companies List */}
          <ListGroup>
            {companies.map((company, idx) => (
              <ListGroup.Item
                key={company.ActivityID || idx}
                className="d-flex align-items-center"
                style={{
                  backgroundColor: 'rgba(70, 130, 180, 0.9)',
                  color: 'white',
                  marginBottom: '10px',
                  borderRadius: '15px',
                  border: 'none',
                  padding: '16px',
                  cursor: 'pointer', // Add cursor pointer for better UX
                }}
                onClick={() => {
                  console.log('Navigating to ExhibitorDetail with ID:', company.ActivityID);
                  navigate(`/exhibitors/${company.ActivityID}`, {
                    state: { exhibitionId: state?.exhibitionId },
                  });
                }}
              >
                {/* Logo */}
                <div className="me-3">
                  {company.logoURL ? (
                    <img
                      src={company.logoURL}
                      alt={company.EponCatGr || company.EponCatEng}
                      style={{
                        width: '70px',
                        height: '70px',
                        borderRadius: '8px',
                        objectFit: 'contain',
                        backgroundColor: 'white',
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: '70px',
                        height: '70px',
                        borderRadius: '8px',
                        backgroundColor: '#f1f3f5',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <i className="bi bi-building" style={{ fontSize: '30px', color: '#666' }}></i>
                    </div>
                  )}
                </div>

                {/* Company Details */}
                <div className="flex-grow-1">
                  <div style={{ fontSize: '16px', fontWeight: 600, marginBottom: '8px' }}>
                    {language === 'en' ? company.EponCatEng : company.EponCatGr}
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="bi bi-geo-alt me-2 text-white" style={{ color: '#666' }}></i>
                    <span style={{ fontSize: '14px', color: 'rgba(255,255,255,0.8)' }}>
                      {language === 'en' ? company.CountryEng : company.CountryGr}
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="bi bi-shop me-2 text-white" style={{ color: '#666' }}></i>
                    <span style={{ fontSize: '14px', color: 'rgba(255,255,255,0.8)' }}>
                      {company.PGR}
                    </span>
                  </div>
                  {company.WebSite && (
                    <Button
                      variant="link"
                      className="p-0"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent navigating to the company page when clicking the link
                        window.open(
                          company.WebSite.startsWith('http')
                            ? company.WebSite
                            : `http://${company.WebSite}`,
                          '_blank'
                        );
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'white',
                        textDecoration: 'none',
                      }}
                    >
                      <i className="bi bi-globe me-2 text-white" style={{ color: '#4c669f' }}></i>
                      <span style={{ color: 'white',  textDecoration: 'underline' }}>
                        {language === 'en' ? 'Visit Website' : 'Επίσκεψη Ιστοσελίδας'}
                      </span>
                    </Button>
                  )}
                </div>

                {/* Chevron */}
                <div>
                  <i className="bi bi-chevron-right" style={{ color: 'white' }}></i>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </motion.div>
      </Container>
    </div>
  );
};

export default ExhibitAssociatedExhibitors;