import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App'; import { AuthProvider } from './context/AuthContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { LanguageProvider } from './context/LanguageContext';

// Register service worker before rendering
serviceWorkerRegistration.register();

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <LanguageProvider>
   <AuthProvider>

      <App />
      </AuthProvider>
    </LanguageProvider>
  </React.StrictMode>
)