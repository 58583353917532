import React, { createContext, useState, useContext } from 'react';

const ExhibitionContext = createContext({
  selectedExhibition: null,
  setSelectedExhibition: () => {},
  exhibitions: [],
  setExhibitions: () => {}
});

export const ExhibitionProvider = ({ children }) => {
  const [selectedExhibition, setSelectedExhibition] = useState(null);
  const [exhibitions, setExhibitions] = useState([]);

  return (
    <ExhibitionContext.Provider value={{ 
      selectedExhibition, 
      setSelectedExhibition, 
      exhibitions, 
      setExhibitions 
    }}>
      {children}
    </ExhibitionContext.Provider>
  );
};

export const useExhibition = () => useContext(ExhibitionContext);