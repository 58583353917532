import React from 'react';
import { Form,InputGroup } from 'react-bootstrap';
import { useLanguage } from '../context/LanguageContext';

function SearchBar({ searchQuery, onSearchChange }) {
  const { language } = useLanguage();

  return (
    <div 
      style={{
        position: 'absolute', 
        top: '80px', 
        left: 0, 
        right: 0, 
        zIndex: 10, 
        padding: '10px', 
        backgroundColor: '#012d50'
      }}
    >
      <InputGroup>
        <InputGroup.Text 
          style={{ 
            backgroundColor: 'rgba(70, 130, 180, 0.9)', 
            borderLeft: 'none',
            borderRight: 'none',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        >
          <i className="bi bi-search" style={{ color: 'white' }}></i>
        </InputGroup.Text>
        <Form.Control 
          type="text" 
          placeholder={
            language === 'en'
              ? 'Search...'
              : 'Αναζήτηση...'
          }
          value={searchQuery}
          onChange={onSearchChange}
          style={{ 
            backgroundColor: 'rgba(70, 130, 180, 0.9)', 
            color: 'white',
            border: 'none',
            borderRadius: '0 10px 10px 0'
          }}
        />
      </InputGroup>
    </div>
   
  );
}

export default SearchBar;