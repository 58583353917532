import axios from 'axios';
import { use } from 'react';
const uid ='b0a10bc92d7ae616624e1f54b3518c2f'
export const fetchExhibitions = async () => {
    try {
     
    
      // Prepare the request params
      const response = await axios.get(`${process.env.REACT_APP_GETEXHIBITION}/${uid}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_USETOKEN}`,
        },
      });
  
     
  
      if (response.data && response.data.results) {
        // Return the transformed exhibitions array
        return response.data.results.map((exhibition) => ({
          value: exhibition.ExCode,
          label: exhibition.Title,
          labelEng: exhibition.TitleEng,
          Descr: exhibition.Descr,
          DescrEng: exhibition.DescrEng,
          bannerUrl: exhibition.Logo,
          VATNeeded: exhibition.VATNeeded,
          CompanyActivitiesListNeeded: exhibition.CompanyActivitiesListNeeded,
          JobListNeeded: exhibition.JobListNeeded,
          RegistrationMsgGr: exhibition.RegistrationMsgGr,
          RegistrationMsgEng: exhibition.RegistrationMsgEng,
          IsRegistered: exhibition.IsRegistered || 0,
          BarCode: exhibition.BarCode || '',
          ProfileID: exhibition.ProfileID || 0,
          HasTicket: exhibition.HasTicket,
        }));
      }
  
      return [];
    } catch (error) {
      console.error('Error fetching exhibitions:', error);
      return [];
    }
  };

export const fetchCompanies = async (exhibitionId) => {
  const requestConfig = {
    method: 'GET',
    url: `${process.env.REACT_APP_LISTCOMPANY}/${exhibitionId}`,
    headers: { Authorization: `Bearer ${process.env.REACT_APP_USETOKEN}` },
  };
  console.log(requestConfig)

  try {
    const response = await axios(requestConfig);
    console.log('API Response:', response.data.results[5]);
    const companyData = response.data.results.map((company) => ({
      ActivityID: company.ActivityID,
      logoURL: company.logoURL || 'https://via.placeholder.com/150',
      CityGr: company.CityGr,
      CityEng: company.CityEng,
      EponCatGr: company.EponCatGr,
      EponCatEng: company.EponCatEng,
      CountryEng: company.CountryEng,
      CountryGr: company.CountryGr,
      StreetEng: company.StreetEng,
      ActivityIDNum: company.ActivityIDNum,
      ContactIDNum: company.ContactIDNum,
      PGR: company.PGR,
      PENG: company.PENG,
      eENG: company.eENG,
      eGR: company.eGR,
      WebSite: company.WebSite,
      RegionEng: company.RegionEng,
      RegionGr: company.RegionGr,
      StreetNum: company.StreetNum,
      BRANCH: company.BRANCH,
      StreetGr: company.StreetGr,
      MainActivityEponCatGr: company.MainActivityEponCatGr,
      MainActivityEponCatEng: company.MainActivityEponCatEng,
    }));
    console.log(companyData)
    return companyData;
  } catch (error) {
    console.error('Error fetching companies:', error);
    throw new Error('Failed to fetch companies');
  }
};

export const fetchExhibits = async (exhibitionId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_LISTEXHIBITS}/${exhibitionId}`, {
      headers: { Authorization: `Bearer ${process.env.REACT_APP_USETOKEN}` },
    });

    const exhibitData = response.data.results.map((exhibit) => ({
      ExhibitCode: exhibit.ExhibitCode,
      DescrGr: exhibit.DescrGr,
      DescrEng: exhibit.DescrEng,
      PavCode: exhibit.PavCode,
      PavDescrGr: exhibit.PavDescrGr,
      PavDescrEng: exhibit.PavDescrEng,
      ExCode: exhibit.ExCode,
      ActivityID: exhibit.ActivityID,
      ExhibitCodeEnc: exhibit.ExhibitCodeEnc
    }));

    // Remove duplicates based on `DescrGr`
    const uniqueExhibits = Array.from(
      new Map(exhibitData.map((item) => [item.DescrGr, item])).values()
    );
   
    return uniqueExhibits;
  } catch (error) {
    console.error('Error fetching exhibits:', error);
    throw new Error('Failed to fetch exhibits');
  }
};

export const fetchPavilions = async (exhibitionId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_PAVILION}/${exhibitionId}`, {
      headers: { Authorization: `Bearer ${process.env.REACT_APP_USETOKEN}` },
    });

    const PavilionData = response.data.results.map((Pavilion) => ({
      PavCode: Pavilion.PavCode,
      PavDescrGr: Pavilion.PavDescrGr,
      PavDescrEng: Pavilion.PavDescrEng,
    }));

    return PavilionData;
  } catch (error) {
    console.error('Error fetching pavilions:', error);
    throw new Error('Failed to fetch pavilions');
  }
};

export const fetchExhibitsByPavilion = async (pavilionId, exhibitionId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_PAVEXHBITIS}/${exhibitionId}/${pavilionId}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_USETOKEN}`,
      },
    });
   console.log(response.data)
    // Check if the API returned an unexpected HTML response
    if (typeof response.data !== 'object' || response.headers['content-type']?.includes('text/html')) {
      console.error('Unexpected API response (Exhibits):', response.data);
      throw new Error('Invalid API response for exhibits.');
    }
    return response.data.results;
  } catch (error) {
    console.error('Error fetching exhibits:', error);
    throw new Error('Failed to fetch exhibits for the pavilion.');
  }
};

export const fetchExhibitorsByPavilion = async (pavilionId, exhibitionId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_PAVEXHIBITOR}/${exhibitionId}/${pavilionId}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_USETOKEN}`,
      },
    });
   console.log(response.data)
    // Check if the API returned an unexpected HTML response
    if (typeof response.data !== 'object' || response.headers['content-type']?.includes('text/html')) {
      console.error('Unexpected API response (Exhibitors):', response.data);
      throw new Error('Invalid API response for exhibitors.');
    }
    return response.data.results;
  } catch (error) {
    console.error('Error fetching exhibitors:', error);
    throw new Error('Failed to fetch exhibitors for the pavilion.');
  }
};




export const sendPostRequest = async (id,token) => {
    try {
      const url = `https://api.helexpo.gr/scan/api/scan`; // Endpoint without ID in the URL
      console.log(`Bearer ${token}`)
      const payload = {
        barcode: id,
      };
      console.log(id)
      const response = await axios.post(url, payload, {
        headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        },
      });
      console.log('res data',response.data)
      return response.data; // Return the API response
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  };



  export const fetchAndStoreUserData = async (username, password) => {
    try {
      console.log('here');
      // Build the request body using JSON.stringify to properly format the data.
      const requestBody = JSON.stringify({
        un: username,
        pwd: password
      });
  
      // Make POST request to your API endpoint
      const response = await fetch('https://api.helexpo.gr/scan/api/login/e', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: requestBody,
      });
  
      console.log(response);
      if (!response.ok) {
        throw new Error(`Server responded with status: ${response.status}`);
      }
  
      // Parse the response data
      const userData = await response.json();
      console.log('Received user data:', userData);
      
      // Check if the userData has a 'name' key
      if (userData.name) {
        console.log('User data is ok: user has a name');
      } else {
        console.warn('User data missing name');
        // Optionally, you can throw an error here or handle the missing name case differently.
      }
      
      // Store all returned values in localStorage
      Object.entries(userData).forEach(([key, value]) => {
        localStorage.setItem(key, JSON.stringify(value));
        console.log(`Stored ${key} in localStorage`);
      });
  
      // Return the data in case it's needed elsewhere
      return userData;
    } catch (error) {
      console.error('Error fetching user data:', error);
      // Store the error message in localStorage if needed.
      localStorage.setItem('fetchError', error.message);
      return null;
    }
  };
  
  export const hasUserData = () => {
    const userId = localStorage.getItem('id');
    console.log('id', userId);
    return userId !== null && userId !== undefined; // Valid user ID exists
  };
  