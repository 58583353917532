import { useState, useEffect } from 'react';
import { fetchExhibitions } from '../utils/fetchQueries'; // Adjust the import path as needed

const token = process.env.REACT_APP_USETOKEN



export const useExhibitionsAndPavilions = () => {
  const [exhibitions, setExhibitions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadExhibitions = async () => {
      try {
        const fetchedExhibitions = await fetchExhibitions();
        setExhibitions(fetchedExhibitions);
        setLoading(false);

        console.log(exhibitions)
      } catch (err) {
        console.error('Error in useExhibitionsAndPavilions:', err);
        setError('Failed to fetch exhibitions');
        setLoading(false);
      }
    };

    loadExhibitions();
  }, []);

  return { exhibitions, loading, error };
};