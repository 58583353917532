import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Row, 
  Col, 
  Spinner, 
  Card, 
  Button, 
  Image 
} from 'react-bootstrap';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useLanguage } from '../context/LanguageContext';
import { FaMapMarkerAlt, FaStore, FaGlobe, FaBuilding } from 'react-icons/fa';

function ExhibitorDetail() {
  const { id } = useParams();
  const location = useLocation();
  const { language } = useLanguage();
  
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Configurations (you'll need to replace these with your actual values)
  const Gexhibitor = process.env.REACT_APP_EXHIBITORURL;
  const token = process.env.REACT_APP_USETOKEN
  const exhibitionId = location.state?.exhibitionId;

  // Scroll to the top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function loadExhibitorDetails() {
      try {
        const response = await axios.get(`${Gexhibitor}/${exhibitionId}/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        
        console.log(response.data);
        setCompany(response.data.result);
        setLoading(false);
      } catch (err) {
        setError('Error fetching data');
        setLoading(false);
        console.error(err);
      }
    }

    if (id && token && exhibitionId) {
      loadExhibitorDetails();
    }
  }, [id, token, exhibitionId]);

  // Loading state
  if (loading) {
    return (
      <Container 
        fluid 
        className="d-flex justify-content-center align-items-center vh-100 text-white"
        style={{ backgroundColor: '#012d50' }}
      >
        <div className="text-center">
          <Spinner animation="border" variant="light" />
          <p className="mt-3">
            {language === 'en' 
              ? 'Loading company details...' 
              : 'Φόρτωση στοιχείων εταιρείας...'}
          </p>
        </div>
      </Container>
    );
  }

  // Error state
  if (error) {
    return (
      <Container 
        fluid 
        className="d-flex justify-content-center align-items-center vh-100 text-white"
        style={{ backgroundColor: '#012d50' }}
      >
        <div className="text-center">
          <FaBuilding className="text-danger mb-3" size={48} />
          <p className="text-danger">{error}</p>
        </div>
      </Container>
    );
  }

  // No company data state
  if (!company) {
    return (
      <Container 
        fluid 
        className="d-flex justify-content-center align-items-center vh-100 text-white"
        style={{ backgroundColor: '#012d50' }}
      >
        <div className="text-center">
          <FaBuilding className="text-danger mb-3" size={48} />
          <p className="text-danger">
            {language === 'en' ? 'No company data available' : 'Δεν υπάρχουν διαθέσιμα στοιχεία εταιρείας'}
          </p>
        </div>
      </Container>
    );
  }

  // Handle website opening
  const handleWebsiteOpen = () => {
    if (company.WebSite) {
      const url = company.WebSite.startsWith('http') 
        ? company.WebSite 
        : `http://${company.WebSite}`;
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <Container 
      fluid 
      className="py-5"
      style={{ 
        backgroundColor: '#012d50',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <Row className="justify-content-center mx-0">
        <Col xs={12} sm={11} md={9} lg={7} xl={5} className="px-3 mb-5">
          <Card className="shadow-lg border-0 rounded-lg overflow-hidden">
            <Card.Body className="text-center p-4">
              {/* Logo Container */}
              <div className="mb-4 d-flex justify-content-center">
                {company.logoURL ? (
                  <div className="bg-light rounded-circle p-2 d-flex align-items-center justify-content-center" 
                    style={{ 
                      width: '140px', 
                      height: '140px',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                    }}>
                    <Image 
                      src={company.logoURL} 
                      alt="Company Logo" 
                      className="mb-0" 
                      style={{ 
                        maxWidth: '120px', 
                        maxHeight: '120px', 
                        objectFit: 'contain' 
                      }} 
                    />
                  </div>
                ) : (
                  <div 
                    className="bg-light rounded-circle mb-3 d-flex align-items-center justify-content-center" 
                    style={{ 
                      width: '140px', 
                      height: '140px',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                    }}
                  >
                    <FaBuilding size={50} className="text-muted" />
                  </div>
                )}
              </div>

              {/* Company Name */}
              <h1 className="h3 mb-2 fw-bold">
                {language === 'en' ? company.EponCatEng : company.EponCatGr}
              </h1>

              {/* Parent Company */}
              {company.BRANCH === 1 && (
                <p className="text-muted fst-italic mb-4">
                  {language === 'en' ? 'House of ' : 'Οίκος '} 
                  {language === 'en' ? company.MainActivityEponCatEng : company.MainActivityEponCatGr}
                </p>
              )}

              {/* Company Info */}
              <div className="text-start bg-light rounded-3 p-4 mb-3" style={{ boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.05)' }}>
                {/* Location */}
                <div className="d-flex align-items-center mb-3">
                  <div className="me-3 text-primary">
                    <FaMapMarkerAlt size={18} />
                  </div>
                  <span>
                    {language === 'en' ? company.CityEng : company.CityGr}
                    {company.CountryEng && `, ${language === 'en' ? company.CountryEng : company.CountryGr}`}
                  </span>
                </div>

                {/* Business Type */}
                <div className="d-flex align-items-center mb-2">
                  <div className="me-3 text-primary">
                    <FaStore size={18} />
                  </div>
                  <span>
                    {company.PGR}
                  </span>
                </div>
              </div>

              {/* Description */}
              {(company.eEng || company.eGR) && (
                <div className="bg-white rounded-3 p-4 mt-3 border" style={{ boxShadow: '0 2px 4px rgba(0,0,0,0.05)' }}>
                  <h2 className="h5 mb-3 fw-bold">
                    {language === 'en' ? 'About Us' : 'Σχετικά με εμάς'}
                  </h2>
                  <p className="text-muted mb-0" style={{ lineHeight: '1.6' }}>
                    {language === 'en' ? company.eENG : company.eGR}
                  </p>
                </div>
              )}

              {/* Website Button */}
              {company.WebSite && (
                <Button 
                  variant="primary" 
                  className="w-100 mt-4 d-flex align-items-center justify-content-center py-2"
                  onClick={handleWebsiteOpen}
                  style={{ 
                    borderRadius: '50px',
                    boxShadow: '0 4px 8px rgba(0,45,80,0.2)'
                  }}
                >
                  <FaGlobe className="me-2" />
                  <span className="fw-bold">
                    {language === 'en' ? 'Visit Website' : 'Επίσκεψη Ιστοσελίδας'}
                  </span>
                </Button>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ExhibitorDetail;