import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, ListGroup, Spinner, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import { useExhibition } from '../context/ExhibitionContext';
import { 
  fetchPavilions, 
  fetchExhibitorsByPavilion, 
  fetchExhibitsByPavilion 
} from '../utils/fetchQueries';

const Pavilions = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const { selectedExhibition } = useExhibition();
  const [pavilions, setPavilions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [loadingPavilion, setLoadingPavilion] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const loadPavilions = useCallback(async () => {
    if (!selectedExhibition?.value) {
      setError(
        language === 'en'
          ? 'Please select an exhibition to continue'
          : 'Παρακαλώ επιλέξτε μια έκθεση για να συνεχίσετε'
      );
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const data = await fetchPavilions(selectedExhibition.value);
      setPavilions(data);
      setError('');
    } catch (err) {
      setError(
        language === 'en'
          ? 'Failed to load pavilions'
          : 'Αποτυχία φόρτωσης περιπτέρων'
      );
      console.error('Error loading pavilions:', err);
    } finally {
      setLoading(false);
    }
  }, [selectedExhibition, language]);

  useEffect(() => {
    loadPavilions();
  }, [loadPavilions]);

  const filteredPavilions = useMemo(() => {
    if (!searchQuery.trim()) return pavilions;

    const searchTerm = searchQuery.toLowerCase().trim();
    return pavilions.filter((pavilion) => {
      const nameEn = pavilion.PavDescrEng?.toLowerCase() || '';
      const nameGr = pavilion.PavDescrGr?.toLowerCase() || '';
      return nameEn.includes(searchTerm) || nameGr.includes(searchTerm);
    });
  }, [pavilions, searchQuery]);

  const handlePavilionPress = async (pavilionId) => {
    if (!selectedExhibition?.value) {
      setError(
        language === 'en'
          ? 'No exhibition selected'
          : 'Δεν έχει επιλεγεί έκθεση'
      );
      return;
    }

    setLoadingPavilion(pavilionId);

    try {
      const [exhibitors, exhibits] = await Promise.all([
        fetchExhibitorsByPavilion(pavilionId, selectedExhibition.value),
        fetchExhibitsByPavilion(pavilionId, selectedExhibition.value),
      ]);
      
      // Enhanced logging for debugging
      console.log('Pavilion ID:', pavilionId);
      console.log('Exhibitors:', exhibitors);
      console.log('Exhibits:', exhibits);
      console.log('Exhibitors count:', exhibitors.length);
      console.log('Exhibits count:', exhibits.length);

      // Log the properties of first exhibitor and exhibit
      if (exhibitors.length > 0) {
        console.log('First Exhibitor Properties:', Object.keys(exhibitors[0]));
      }
      if (exhibits.length > 0) {
        console.log('First Exhibit Properties:', Object.keys(exhibits[0]));
      }

      navigate(`/pavilion-exhibit-list/${pavilionId}`, {
        state: {
          exhibitors,
          exhibits,
          exhibitionId: selectedExhibition.value,
          pavilionName:
            pavilions.find((p) => p.PavCode === pavilionId)?.[
              language === 'en' ? 'PavDescrEng' : 'PavDescrGr'
            ] || '',
        },
      });
    } catch (err) {
      setError(
        language === 'en'
          ? 'Failed to load pavilion details'
          : 'Αποτυχία φόρτωσης λεπτομερειών περιπτέρου'
      );
      console.error('Error loading pavilion details:', err);
    } finally {
      setLoadingPavilion(null);
    }
  };

  // Rest of the component remains the same...
  return (
    <div
      style={{
        backgroundColor: '#012d50',
        minHeight: '100vh',
        padding: '16px',
      }}
    >
      <Container>
        {/* Search Bar */}
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder={
              language === 'en' ? 'Search pavilions...' : 'Αναζήτηση περιπτέρων...'
            }
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              backgroundColor: 'rgba(255,255,255,0.9)',
              color: '#012d50',
              borderRadius: '15px',
              padding: '12px',
            }}
          />
        </Form.Group>

        {/* Empty State */}
        {filteredPavilions.length === 0 && (
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ height: '50vh', color: 'white' }}
          >
            <i className="bi bi-search" style={{ fontSize: '48px' }}></i>
            <p className="mt-3 text-center">
              {searchQuery.length > 0
                ? language === 'en'
                  ? 'No pavilions found matching your search'
                  : 'Δεν βρέθηκαν περίπτερα που να ταιριάζουν με την αναζήτησή σας'
                : language === 'en'
                  ? 'No pavilions found for this exhibition'
                  : 'Δεν βρέθηκαν περίπτερα για αυτήν την έκθεση'}
            </p>
          </div>
        )}

        {/* Pavilions List */}
        <ListGroup>
          {filteredPavilions.map((pavilion) => (
            <ListGroup.Item
              key={pavilion.PavCode}
              className="d-flex align-items-center"
              style={{
                backgroundColor: 'rgba(70, 130, 180, 0.9)',
                color: 'white',
                marginBottom: '10px',
                borderRadius: '15px',
                border: 'none',
                padding: '12px',
                cursor: 'pointer',
              }}
              onClick={() => handlePavilionPress(pavilion.PavCode)}
              disabled={loadingPavilion === pavilion.PavCode}
            >
              <div className="flex-grow-1">
                <div style={{ fontSize: '16px', fontWeight: 600 }}>
                  {language === 'en' ? pavilion.PavDescrEng : pavilion.PavDescrGr}
                </div>
              </div>
              {loadingPavilion === pavilion.PavCode ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                <i className="bi bi-chevron-right" style={{ color: 'white' }}></i>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Container>
    </div>
  );
};

export default Pavilions;