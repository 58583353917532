import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

// Updated fetchAndStoreUserData function that accepts username and password
export const fetchAndStoreUserData = async (username, password) => {
  try {
    const requestBody = JSON.stringify({
      un: username,
      pwd: password
    });

    const response = await fetch('https://api.helexpo.gr/scan/api/login/e', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: requestBody,
    });

    if (!response.ok) {
      throw new Error(`Server responded with status: ${response.status}`);
    }

    const userData = await response.json();
    console.log('Received user data:', userData);

    // Store all returned values in localStorage
    Object.entries(userData).forEach(([key, value]) => {
      localStorage.setItem(key, JSON.stringify(value));
      console.log(`Stored ${key} in localStorage`);
    });

    return userData;
  } catch (error) {
    console.error('Error fetching user data:', error);
    localStorage.setItem('fetchError', error.message);
    return null;
  }
};

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Pass the user-inputted credentials to the API call
      const userData = await fetchAndStoreUserData(username, password);

      if (userData) {
        // Assuming the API returns token and user details
        login(userData.token, userData.user);
        navigate('/');
      } else {
        alert('Login failed. Please try again.');
      }
    } catch (error) {
      if (!navigator.onLine) {
        alert('You are offline. Please connect to the internet to log in.');
      } else {
        alert('Login failed. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <style jsx>{`
        /* Base Styles */
        :root {
          --primary-color: #0284c7;
          --secondary-color: #025483;
          --accent-color: #0ea5e9;
          --background-color: #012d50;
          --card-bg: #013a66;
          --text-color: #f8fafc;
          --text-secondary: #cbd5e1;
          --border-radius: 16px;
          --spacing-xs: 8px;
          --spacing-sm: 16px;
          --spacing-md: 24px;
          --spacing-lg: 32px;
          --transition-speed: 0.2s;
        }
        
        .login-container {
          min-height: 100dvh;
          background-color: var(--background-color);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: var(--spacing-md);
          font-family: 'SF Pro Display', system-ui, sans-serif;
          color: var(--text-color);
        }
        
        .login-card {
          background-color: var(--card-bg);
          border-radius: var(--border-radius);
          width: 100%;
          max-width: 420px;
          overflow: hidden;
          box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25);
        }
        
        .card-header {
          padding: var(--spacing-md);
          background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
          text-align: center;
        }
        
        .card-header h1 {
          margin: 0;
          font-size: 1.75rem;
          font-weight: 700;
          letter-spacing: -0.5px;
        }
        
        .card-header p {
          margin: var(--spacing-xs) 0 0;
          opacity: 0.9;
        }
        
        .login-form {
          padding: var(--spacing-md);
        }
        
        .form-group {
          margin-bottom: var(--spacing-md);
        }
        
        .form-label {
          display: block;
          margin-bottom: var(--spacing-xs);
          font-weight: 500;
        }
        
        .input-group {
          position: relative;
        }
        
        .input-icon {
          position: absolute;
          left: var(--spacing-sm);
          top: 50%;
          transform: translateY(-50%);
          font-size: 1.25rem;
          color: var(--text-secondary);
        }
        
        .form-input {
          width: 100%;
          padding: var(--spacing-sm) var(--spacing-sm) var(--spacing-sm) calc(var(--spacing-sm) * 3);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: calc(var(--border-radius) / 2);
          background-color: rgba(255, 255, 255, 0.05);
          color: var(--text-color);
          font-size: 1rem;
          transition: border-color var(--transition-speed), box-shadow var(--transition-speed);
        }
        
        .form-input:focus {
          outline: none;
          border-color: var(--accent-color);
          box-shadow: 0 0 0 2px rgba(14, 165, 233, 0.3);
        }
        
        .form-input::placeholder {
          color: var(--text-secondary);
          opacity: 0.7;
        }
        
        .form-checkbox {
          display: flex;
          align-items: center;
        }
        
        .form-checkbox input {
          margin-right: var(--spacing-xs);
        }
        
        .form-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: var(--spacing-md);
        }
        
        .forgot-password {
          color: var(--accent-color);
          text-decoration: none;
          font-size: 0.9rem;
          transition: opacity var(--transition-speed);
        }
        
        .forgot-password:hover {
          opacity: 0.8;
        }
        
        .login-button {
          width: 100%;
          padding: var(--spacing-sm);
          background-color: var(--accent-color);
          color: var(--text-color);
          border: none;
          border-radius: calc(var(--border-radius) / 2);
          font-size: 1rem;
          font-weight: 600;
          cursor: pointer;
          transition: background-color var(--transition-speed), transform var(--transition-speed);
        }
        
        .login-button:hover {
          background-color: var(--primary-color);
          transform: translateY(-2px);
        }
        
        .login-button:disabled {
          background-color: rgba(255, 255, 255, 0.2);
          cursor: not-allowed;
          transform: none;
        }
        
        .back-button {
          margin-top: var(--spacing-md);
          background-color: transparent;
          border: 1px solid var(--text-secondary);
          border-radius: calc(var(--border-radius) / 2);
          padding: var(--spacing-xs) var(--spacing-md);
          color: var(--text-secondary);
          font-size: 0.9rem;
          cursor: pointer;
          transition: background-color var(--transition-speed), color var(--transition-speed);
        }
        
        .back-button:hover {
          background-color: var(--text-secondary);
          color: var(--background-color);
        }
        
        @media (max-width: 480px) {
          .login-card {
            max-width: 100%;
          }
          
          .card-header h1 {
            font-size: 1.5rem;
          }
        }
        
        /* Loading spinner */
        .spinner {
          width: 20px;
          height: 20px;
          border: 2px solid rgba(255, 255, 255, 0.3);
          border-radius: 50%;
          border-top-color: var(--text-color);
          animation: spin 1s ease-in-out infinite;
          margin-left: var(--spacing-xs);
        }
        
        @keyframes spin {
          to { transform: rotate(360deg); }
        }
      `}</style>
      
      <div className="login-container">
        <div className="login-card">
          <div className="card-header">
            <h1>Exhibitor Login</h1>
            <p>Access your exhibition dashboard</p>
          </div>
          
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="username" className="form-label">UserName</label>
              <div className="input-group">
                <span className="input-icon">✉️</span>
                <input
                  id="username"
                  type="username"
                  className="form-input"
                  placeholder="Enter your username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
            </div>
            
            <div className="form-group">
              <label htmlFor="password" className="form-label">Password</label>
              <div className="input-group">
                <span className="input-icon">🔒</span>
                <input
                  id="password"
                  type="password"
                  className="form-input"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            </div>
            
            <div className="form-footer">
              <label className="form-checkbox">
                <input 
                  type="checkbox" 
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                Remember me
              </label>
            </div>
            
            <button 
              type="submit" 
              className="login-button"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  Logging in
                  <div className="spinner"></div>
                </>
              ) : "Log In"}
            </button>
          </form>
        </div>
        
        <button 
          className="back-button"
          onClick={() => navigate('/')}
        >
          Back to Exhibitions
        </button>
      </div>
    </>
  );
}

export default Login;
