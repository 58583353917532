
// MapScreen.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Container, Spinner, Button, Alert } from 'react-bootstrap';
import { useExhibition } from '../context/ExhibitionContext';
import { useLanguage } from '../context/LanguageContext';

const MapScreen = () => {
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState('Initializing...');
  const [iframeKey, setIframeKey] = useState(0);
  const [showLocationPrompt, setShowLocationPrompt] = useState(false);
  const [dataInitialized, setDataInitialized] = useState(false);
  const [containerHeight, setContainerHeight] = useState('calc(100vh - 60px)');
  
  const iframeRef = useRef(null);
  const containerRef = useRef(null);
  const { selectedExhibition } = useExhibition();
  const { language } = useLanguage();
  // Fetch token from environment variable
  const gettoken = process.env.REACT_APP_USETOKEN;

  // Adjust container height to account for navbar
  useEffect(() => {
    const calculateHeight = () => {
      const navbarHeight = 60;
      const height = `calc(100vh - ${navbarHeight}px)`;
      setContainerHeight(height);
    };

    calculateHeight();
    window.addEventListener('resize', calculateHeight);
    
    return () => {
      window.removeEventListener('resize', calculateHeight);
    };
  }, []);

  // Pre-initialize data before iframe loads
  useEffect(() => {
    console.log('Pre-initializing data...');
    const preInitializeData = async () => {
      try {
        // Fetch token
        console.log('Fetching token...');
        setStatus('Fetching token...');
        const userToken = gettoken;
        console.log('Token fetched:', userToken || 'No token available');
        setToken(userToken || '');
        
        setDataInitialized(true);
        setIsLoading(false);
      } catch (error) {
        console.error('Error in preInitializeData:', error);
        setStatus(`Error: ${error.message}`);
      }
    };

    preInitializeData();
  }, [gettoken]);

  // Construct iframe URL with initial data
  const constructIframeUrl = () => {
    const baseUrl = "https://services-helexpo.gr/mapp2";
    const initialData = {
      exhibition: selectedExhibition?.value || '',
      token,
      language,
      // Not sending location here anymore - iframe will handle it
    };
    
    // Add initial data as URL parameters to speed up loading
    const queryString = new URLSearchParams({
      initialData: JSON.stringify(initialData)
    }).toString();
    
    return `${baseUrl}#/?${queryString}`;
  };

  // Send data to iframe
  const sendDataToIframe = () => {
    console.log('Attempting to send data to iframe...');
    if (!iframeRef.current) {
      console.log('Cannot send data - iframeRef:', iframeRef.current);
      return;
    }

    try {
      const data = {
        exhibition: selectedExhibition?.value || '',
        token,
        language,
        // Not sending location here anymore - iframe will handle it
      };

      console.log('Data prepared to send:', data);
      iframeRef.current.contentWindow.postMessage(JSON.stringify(data), '*');
      console.log('Data sent to iframe successfully');
    } catch (error) {
      console.error('Error sending data to iframe:', error);
    }
  };

  // Only send data when iframe is loaded
  useEffect(() => {
    if (!isLoading && iframeRef.current) {
      console.log('Triggering sendDataToIframe due to essential data updates');
      sendDataToIframe();
    }
  }, [isLoading, token, language, selectedExhibition]);

  // Reload iframe
  const reloadIframe = () => {
    console.log('Reloading iframe...');
    setIframeKey((prevKey) => prevKey + 1);
  };

  if (isLoading) {
    return (
      <Container
        fluid
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ 
          backgroundColor: '#012d50', 
          height: containerHeight, 
          marginTop: '60px',
          overflow: 'hidden' 
        }}
        ref={containerRef}
      >
        <Spinner animation="border" variant="light" />
        <p className="text-white mt-3">Loading map...</p>
        <p className="text-muted mt-1">{status}</p>
      </Container>
    );
  }

  const iframeUrl = constructIframeUrl();
  console.log('Rendering iframe - iframeKey:', iframeKey, 'URL:', iframeUrl);

  return (
    <Container 
      fluid 
      className="d-flex flex-column p-0" 
      style={{ 
        backgroundColor: '#012d50', 
        height: containerHeight,
        overflow: 'hidden' 
      }}
      ref={containerRef}
    >
      <div className="flex-grow-1 position-relative" style={{ height: '100%', overflow: 'hidden' }}>
        <iframe
          key={iframeKey}
          ref={iframeRef}
          src={iframeUrl}
          className="w-100 h-100 border-0"
          style={{ position: 'relative' }}
          title="Map"
          allow="geolocation"
          onLoad={() => {
            console.log('Iframe loaded');
            setStatus('Loading complete.');
            sendDataToIframe();
          }}
          onError={(e) => {
            console.error('Iframe error:', e);
            setStatus('Error loading iframe');
          }}
        />
      </div>
    </Container>
  );
};

export default MapScreen;