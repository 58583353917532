import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useExhibition } from '../context/ExhibitionContext';
import { useExhibitionsAndPavilions } from '../hooks/useExhibitionsHook';
import { useLanguage, i18n } from '../context/LanguageContext';
import { hasUserData } from '../utils/fetchQueries';

function Home() {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const { selectedExhibition, setSelectedExhibition, exhibitions, setExhibitions } = useExhibition();
  const { exhibitions: fetchedExhibitions, loading, error } = useExhibitionsAndPavilions();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  useEffect(() => {
    if (fetchedExhibitions && fetchedExhibitions.length > 0) {
      setExhibitions(fetchedExhibitions);
    }
  }, [fetchedExhibitions, setExhibitions]);
  
   useEffect(() => {
     const checkUserData = () => {
       try {
         const hasData = hasUserData();
         setIsLoggedIn(!!hasData);
         console.log('User login status:', !!hasData);
       } catch (error) {
         console.error('Error checking user data:', error);
         setIsLoggedIn(false);
       }
     };
    
     checkUserData();
     window.addEventListener('storage', checkUserData);
     return () => window.removeEventListener('storage', checkUserData);
   }, []);
  
  const handleExhibitionSelect = (exhibition) => {
    setSelectedExhibition(exhibition);
  };
  
  const handleLoginClick = () => {
    navigate('/exhibitor-login');
  };
  
  const handleQrScannerClick = () => {
    navigate('/qr-scanner');
  };
  
  const MenuOption = ({ icon, label, onClick, variant = "primary" }) => (
    <button
      onClick={onClick}
      className={`menu-option ${variant}`}
    >
      <span className="menu-icon">{icon}</span>
      <span className="menu-label">{label}</span>
    </button>
  );
  
  const renderExhibitionSelection = () => {
    if (loading) {
      return (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      );
    }
    
    return (
      <div className="exhibition-selection">
        <div className="exhibition-grid">
          {exhibitions && exhibitions.map((exhibition) => (
            <div
              key={exhibition.value}
              onClick={() => handleExhibitionSelect(exhibition)}
              className="exhibition-card"
            >
              <div className="card-media">
                {exhibition.bannerUrl ? (
                  <img
                    src={exhibition.bannerUrl}
                    alt={exhibition.label}
                    loading="lazy"
                  />
                ) : (
                  <div className="exhibition-placeholder">
                    <span>{exhibition.label[0]}</span>
                  </div>
                )}
              </div>
              <div className="card-content">
                <h3>{exhibition.label}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  const renderMainMenu = () => {
    return (
      <div className="main-menu">
        <header className="exhibition-header">
          <div className="exhibition-banner">
            {selectedExhibition?.bannerUrl ? (
              <img
                src={selectedExhibition.bannerUrl}
                alt={selectedExhibition.label}
                loading="lazy"
              />
            ) : (
              <div className="banner-placeholder">
                <span>{selectedExhibition?.label?.[0]}</span>
              </div>
            )}
            <div className="exhibition-title">
             
            </div>
          </div>
        </header>
        <div className="menu-grid">
          <MenuOption
            icon="🏢"
            label={i18n.t('companies')}
            onClick={() => navigate('/exhibitors')}
          />
          <MenuOption
            icon="🖼️"
            label={i18n.t('exhibits')}
            onClick={() => navigate('/exhibits')}
          />
          <MenuOption
            icon="🏛️"
            label={i18n.t('pavilions')}
            onClick={() => navigate('/pavilions')}
          />
          <MenuOption
            icon="🗺️"
            label={i18n.t('mapScreen')}
            onClick={() => navigate('/map')}
          />
          {isLoggedIn ? (
            <MenuOption
              icon="📷"
              label={i18n.t('scanQrCode')}
              onClick={handleQrScannerClick}
            />
          ) : (
            <MenuOption
              icon="🔑"
              label={i18n.t('login') || "Exhibitor Login"}
              onClick={handleLoginClick}
            />
          )}
        </div>
        <button
          className="back-button"
          onClick={() => setSelectedExhibition(null)}
        >
          Choose Another Exhibition
        </button>
      </div>
    );
  };
  
  return (
    <>
      <style jsx>{`
        /* Base Styles */
        :root {
          --primary-color: #0284c7;
          --secondary-color: #025483;
          --accent-color: #0ea5e9;
          --background-color: #012d50; /* Keeping the original background color */
          --card-bg: #013a66;
          --text-color: #f8fafc;
          --text-secondary: #cbd5e1;
          --border-radius: 16px;
          --spacing-xs: 8px;
          --spacing-sm: 16px;
          --spacing-md: 24px;
          --spacing-lg: 32px;
          --header-height: 220px;
          --transition-speed: 0.2s;
        }
        /* App Container */
        .exhibition-selection,
        .main-menu {
          min-height: 100dvh;
          background-color: var(--background-color);
          color: var(--text-color);
          padding: var(--spacing-sm);
          font-family: 'SF Pro Display', system-ui, sans-serif;
          display: flex;
          flex-direction: column;
        }
        /* Loading State */
        .loading-container {
          height: 100dvh;
          display: grid;
          place-items: center;
        }
        .loading-spinner {
          width: 40px;
          height: 40px;
          border: 3px solid rgba(255, 255, 255, 0.1);
          border-radius: 50%;
          border-top-color: var(--accent-color);
          animation: spin 1s ease-in-out infinite;
        }
        @keyframes spin {
          to { transform: rotate(360deg); }
        }
        /* Exhibition Grid */
        .exhibition-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          gap: var(--spacing-md);
          margin-top: var(--spacing-md);
          padding-bottom: var(--spacing-md);
        }
        .exhibition-card {
          background-color: var(--card-bg);
          border-radius: var(--border-radius);
          overflow: hidden;
          cursor: pointer;
          transition: transform var(--transition-speed), box-shadow var(--transition-speed);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
          height: 100%;
          display: flex;
          flex-direction: column;
        }
        .exhibition-card:hover {
          transform: translateY(-4px);
          box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25);
        }
        .card-media {
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: 56.25%; /* 16:9 aspect ratio */
          background-color: var(--secondary-color);
          overflow: hidden;
        }
        .card-media img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform var(--transition-speed);
        }
        .exhibition-card:hover .card-media img {
          transform: scale(1.05);
        }
        .exhibition-placeholder {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: grid;
          place-items: center;
          background-color: var(--secondary-color);
          color: var(--text-color);
          font-size: 3rem;
          font-weight: bold;
        }
        .card-content {
          padding: var(--spacing-sm);
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .card-content h3 {
          margin: 0;
          font-size: 1.25rem;
          font-weight: 600;
          letter-spacing: -0.5px;
        }
        /* Main Menu Styling */
        .exhibition-header {
          margin-bottom: var(--spacing-md);
        }
        .exhibition-banner {
          position: relative;
          border-radius: var(--border-radius);
          overflow: hidden;
          height: var(--header-height);
          margin-bottom: var(--spacing-md);
        }
        .exhibition-banner img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .banner-placeholder {
          width: 100%;
          height: 100%;
          display: grid;
          place-items: center;
          background-color: var(--secondary-color);
          color: var(--text-color);
          font-size: 4rem;
          font-weight: bold;
        }
        .exhibition-title {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: var(--spacing-md);
          background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
          color: var(--text-color);
        }
        .exhibition-title h1 {
          margin: 0 0 var(--spacing-xs) 0;
          font-size: 1.75rem;
          font-weight: 700;
          letter-spacing: -0.5px;
        }
        .exhibition-title p {
          margin: 0;
          opacity: 0.9;
          font-size: 1rem;
        }
        /* Menu Grid */
        .menu-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
          gap: var(--spacing-md);
          margin-bottom: var(--spacing-md);
        }
        .menu-option {
          background-color: var(--card-bg);
          border: none;
          border-radius: var(--border-radius);
          padding: var(--spacing-md) var(--spacing-sm);
          color: var(--text-color);
          text-align: center;
          cursor: pointer;
          transition: transform var(--transition-speed), background-color var(--transition-speed);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          min-height: 120px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
        }
        .menu-option:hover {
          transform: translateY(-4px);
          background-color: var(--primary-color);
        }
        .menu-icon {
          font-size: 2rem;
          margin-bottom: var(--spacing-xs);
        }
        .menu-label {
          font-size: 1rem;
          font-weight: 500;
        }
        /* Back Button */
        .back-button {
          background-color: rgba(255, 255, 255, 0.1);
          border: 1px solid var(--text-secondary);
          border-radius: var(--border-radius);
          padding: var(--spacing-sm);
          color: var(--text-secondary);
          font-size: 1rem;
          font-weight: 500;
          cursor: pointer;
          transition: background-color var(--transition-speed), color var(--transition-speed);
          margin-top: auto;
          align-self: center;
          width: fit-content;
        }
        .back-button:hover {
          background-color: var(--text-secondary);
          color: var(--background-color);
        }
        @media (max-width: 768px) {
          .menu-grid {
            grid-template-columns: repeat(2, 1fr);
          }
          .exhibition-grid {
            grid-template-columns: 1fr;
          }
          .exhibition-banner {
            height: 180px;
          }
        }
        @media (max-width: 480px) {
          :root {
            --spacing-md: 16px;
            --spacing-lg: 24px;
          }
          .menu-grid {
            gap: var(--spacing-sm);
          }
          .menu-option {
            min-height: 100px;
          }
        }
      `}</style>
      {!selectedExhibition ? renderExhibitionSelection() : renderMainMenu()}
    </>
  );
}

export default Home;