import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Container, ListGroup, Spinner } from 'react-bootstrap';
import { useLanguage } from '../context/LanguageContext';
import { useExhibition } from '../context/ExhibitionContext';
import { fetchExhibits } from '../utils/fetchQueries';
import SearchBar from '../components/SearchBar'; // Import the same SearchBar as Exhibitors
import { useNavigate } from 'react-router-dom';
const ExhibitsListScreen = () => {
  const navigate = useNavigate(); // Add this hook
  const [exhibits, setExhibits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const { language } = useLanguage();
  const { selectedExhibition } = useExhibition();

  // Load exhibits
  const loadExhibits = useCallback(async () => {
    if (!selectedExhibition?.value) {
      setError(
        language === 'en'
          ? 'No exhibition selected'
          : 'Δεν έχει επιλεγεί έκθεση'
      );
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const data = await fetchExhibits(selectedExhibition.value);
      console.log('Fetched exhibits:', data);
      setExhibits(data);
      setError('');
    } catch (err) {
      console.error('Fetch error:', err);
      setError(
        language === 'en'
          ? 'Failed to load exhibits'
          : 'Αποτυχία φόρτωσης εκθεμάτων'
      );
    } finally {
      setLoading(false);
    }
  }, [selectedExhibition, language]);

  useEffect(() => {
    loadExhibits();
  }, [loadExhibits]);

  // Filter exhibits based on search query
  const filteredExhibits = useMemo(() => {
    if (!searchQuery.trim()) return exhibits;

    const searchTerm = searchQuery.toLowerCase().trim();
    return exhibits.filter((item) =>
      (item.DescrEng?.toLowerCase() || '').includes(searchTerm) ||
      (item.DescrGr?.toLowerCase() || '').includes(searchTerm)
    );
  }, [exhibits, searchQuery]);

  // Loading state
  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          height: '100vh',
          backgroundColor: '#012d50',
          color: 'white',
        }}
      >
        <div className="text-center">
          <Spinner animation="border" variant="light" />
          <p className="mt-3">
            {language === 'en' ? 'Loading exhibits...' : 'Φόρτωση εκθεμάτων...'}
          </p>
        </div>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          height: '100vh',
          backgroundColor: '#012d50',
          color: 'white',
        }}
      >
        <p className="text-danger">{error}</p>
        <button
          className="btn btn-light mt-3"
          onClick={loadExhibits}
        >
          {language === 'en' ? 'Retry' : 'Επανάληψη'}
        </button>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: '#012d50',
        minHeight: '100vh',
        paddingTop: '60px', // Add padding to account for fixed search bar
      }}
    >
      {/* Fixed Search Bar */}
      <SearchBar
        searchQuery={searchQuery}
        onSearchChange={(e) => setSearchQuery(e.target.value)}
      />

      {/* Empty State */}
      {filteredExhibits.length === 0 && (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ height: '50vh', color: 'white' }}
        >
          <i className="bi bi-search" style={{ fontSize: '48px' }}></i>
          <p className="mt-3 text-center">
            {language === 'en'
              ? 'No exhibits found matching your search'
              : 'Δεν βρέθηκαν εκθέματα που να ταιριάζουν με την αναζήτησή σας'}
          </p>
        </div>
      )}

<ListGroup>
        {filteredExhibits.map((exhibit) => (
          <ListGroup.Item
            key={exhibit.ExhibitCode}
            className="d-flex align-items-center"
            style={{
              backgroundColor: 'rgba(70, 130, 180, 0.9)',
              color: 'white',
              marginBottom: '10px',
              borderRadius: '15px',
              border: 'none',
            }}
            onClick={() => {
              navigate(`/exhibit-associated-exhibitors/${exhibit.ExhibitCodeEnc}`, {
                state: { exhibitionId: selectedExhibition?.value },
              });
            }}
          >
            <div className="me-3"
              >
         <div style={{ 
  fontSize: '24px',
  width: '50px',
  height: '50px',
  borderRadius: '25px',
  backgroundColor: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#666'
}}>
  🖼️
</div>
            </div>
            <div className="flex-grow-1">
              <div style={{ fontWeight: 600 }}>
                {language === 'en' ? exhibit.DescrEng : exhibit.DescrGr}
              </div>
              <div style={{ color: 'rgba(255,255,255,0.8)' }}>
                {language === 'en'
                  ? exhibit.CategoryEn || 'Exhibit'
                  : exhibit.CategoryGr || 'Έκθεμα'}
              </div>
            </div>
            <div>
              <i className="bi bi-chevron-right" style={{ color: 'white' }}></i>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default ExhibitsListScreen;