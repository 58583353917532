import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Container, ListGroup, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import { useExhibition } from '../context/ExhibitionContext';
import { fetchCompanies } from '../utils/fetchQueries';
import SearchBar from '../components/SearchBar'; // Import the new component
import { FaMapMarkerAlt, FaStore, FaGlobe, FaBuilding } from 'react-icons/fa';

function Exhibitors() {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const { selectedExhibition } = useExhibition();

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  // Load companies
  const loadCompanies = useCallback(async () => {
    if (!selectedExhibition) {
      setError(
        language === 'en'
          ? 'Please select an exhibition and ensure authentication'
          : 'Παρακαλώ επιλέξτε έκθεση και επαληθεύστε την ταυτότητα'
      );
      return;
    }

    try {
      setLoading(true);
      const data = await fetchCompanies(selectedExhibition.value);
      setCompanies(data);
      setError('');
    } catch (err) {
      setError(
        language === 'en'
          ? 'Failed to fetch companies'
          : 'Αποτυχία φόρτωσης εταιρειών'
      );
    } finally {
      setLoading(false);
    }
  }, [selectedExhibition, language]);

  // Fetch companies when component mounts or dependencies change
  useEffect(() => {
    loadCompanies();
  }, [loadCompanies]);

  // Filter companies based on search query
  const filteredCompanies = useMemo(() => {
    if (!searchQuery.trim()) return companies;

    const searchTerm = searchQuery.toLowerCase().trim();
    return companies.filter((company) => {
      const nameMatches = (name) => 
        name?.toLowerCase().includes(searchTerm) || false;

      return (
        nameMatches(company.EponCatEng) ||
        nameMatches(company.EponCatGr) ||
        nameMatches(company.CityEn) ||
        nameMatches(company.CityGr)
      );
    });
  }, [companies, searchQuery]);

  // Loading state
  if (loading) {
    return (
      <div 
        className="d-flex justify-content-center align-items-center" 
        style={{ 
          height: '100vh', 
          backgroundColor: '#012d50', 
          color: 'white' 
        }}
      >
        <div className="text-center">
          <Spinner animation="border" variant="light" />
          <p className="mt-3">
            {language === 'en' 
              ? 'Loading exhibitors...' 
              : 'Φόρτωση στοιχείων εκθετών...'}
          </p>
        </div>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div 
        className="d-flex justify-content-center align-items-center" 
        style={{ 
          height: '100vh', 
          backgroundColor: '#012d50', 
          color: 'white' 
        }}
      >
        <p className="text-danger">{error}</p>
      </div>
    );
  }

  return (
    <div 
      style={{ 
        backgroundColor: '#012d50', 
        minHeight: '100vh', 
        paddingTop: '60px' // Add padding to account for fixed search bar
      }}
    >
      {/* Fixed Search Bar */}
      <SearchBar 
        searchQuery={searchQuery} 
        onSearchChange={(e) => setSearchQuery(e.target.value)} 
      />

      {/* Empty State */}
      {filteredCompanies.length === 0 && (
        <div 
          className="d-flex flex-column align-items-center justify-content-center" 
          style={{ height: '50vh', color: 'white' }}
        >
          <i className="bi bi-search" style={{ fontSize: '48px' }}></i>
          <p className="mt-3 text-center">
            {language === 'en'
              ? 'No companies found matching your search'
              : 'Δεν βρέθηκαν εταιρείες που να ταιριάζουν με την αναζήτησή σας'}
          </p>
        </div>
      )}

      {/* Company List */}
      <ListGroup>
        {filteredCompanies.map((company) => (
          <ListGroup.Item 
            key={company.ActivityID}
            className="d-flex align-items-center"
            style={{
              backgroundColor: 'rgba(70, 130, 180, 0.9)',
              color: 'white',
              marginBottom: '10px',
              borderRadius: '15px',
              border: 'none'
            }}
            onClick={() => navigate(`/exhibitors/${company.ActivityID}`, {
              state: { 
                exhibitionId: selectedExhibition?.value 
              }
            })}
          >
            {/* Logo */}
            <div className="me-3">
              {company.logoURL !== 'https://via.placeholder.com/150' ? (
              <img
                src={company.logoURL}
                alt={company.EponCatGr}
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '25px',
                  objectFit: 'contain',
                  backgroundColor: 'white',
                }}
              />
            ) : (
              <div
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '25px',
                  backgroundColor: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#666',
                  fontSize: '24px'
                }}
              >
                🏢
              </div>
            )}
             
            </div>

            {/* Company Details */}
            <div className="flex-grow-1">
              <div style={{ fontWeight: 600 }}>
                {language === 'en' ? company.EponCatEng : company.EponCatGr}
              </div>
              <div style={{ color: 'rgba(255,255,255,0.8)' }}>
                {language === 'en' ? company.CityEng : company.CityGr}
              </div>
            </div>
            {/* Chevron */}
            <div>
              <i className="bi bi-chevron-right" style={{ color: 'white' }}></i>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
}

export default Exhibitors;